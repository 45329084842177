jQuery(document).ready(function() {
   jQuery(".dropdown-toggle").dropdown();
    
    //$('.dropdown-toggle').mouseover(function() {
        //$(this).dropdown('toggle');
    //});

    //fix per evitare che in android il menu si chiuda appena cliccato il link
    $(".dropdown-menu a").on({
        "click":function(e){
            e.stopPropagation();
        }
    });
});

/*
    $(window).resize(function(){    
        if ($(window).width() <= 991) {  
             $(".dropdown").unbind("mouseover");
        }
        else {
            $(".dropdown").bind("mouseover");
        }
    });

     $('.dropdown-toggle').mouseover(function() {
        $(this).dropdown('toggle');
    });*/
