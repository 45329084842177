/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

 (function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages

				// HAMBURGER MENU
				// --------------
				$('.m-hamburger').click(function() {
					$(this).toggleClass('is-open');
				});

				// ADD DATA-VALIDATION ATTRIBUTES TO CF7 INPUT
				// -------------------------------------------
				$('.wpcf7-validates-as-required[type=text]').attr('data-validation', 'required');

				$('.wpcf7-validates-as-required[type=email]').attr('data-validation', 'email');

				$('.wpcf7-validates-as-required[type=url]').attr('data-validation', 'url');

				$('.wpcf7-validates-as-required[type=tel]').attr('data-validation', 'number');

				$('.wpcf7-validates-as-required[type=number]').attr('data-validation', 'number');

				$('.wpcf7-validates-as-required[type=date]').attr('data-validation', 'date');

				$('textarea.wpcf7-validates-as-required').attr('data-validation', 'required');

				$('select.wpcf7-validates-as-required').attr('data-validation', 'required');

				$('.wpcf7-validates-as-required input[type=checkbox]').attr('data-validation', 'required');
				$('.wpcf7-validates-as-required[type=checkbox]').attr('data-validation', 'required');

				$('.wpcf7-validates-as-required[type=file]').attr('data-validation', 'required');


				// INIT JQUERY FORM VALIDATOR
				// --------------------------
				var ita = {
					errorTitle : 'Invio della richiesta fallito!',
					requiredFields : 'Questo campo è obbligatorio',
					badTime : 'Non hai inserito un orario corretto',
					badEmail : 'L\'indirizzo email non è corretto',
					badTelephone : 'Non hai inserito un numero di telefono corretto',
					badSecurityAnswer : 'Non hai dato una risposta esatta alla domanda di sicurezza',
					badDate : 'Non hai inserito una data corretta',
					lengthBadStart : 'Devi inserire una risposta tra ',
					lengthBadEnd : ' caratteri',
					lengthTooLongStart : 'Non hai dato una risposta più lunga di ',
					lengthTooShortStart : 'Non hai dato una risposta più corta di ',
					notConfirmed : 'I valori non possono essere confermati',
					badDomain : 'Valore di dominio non corretto',
					badUrl : 'La tua risposta non è un URL corretto',
					badCustomVal : 'Hai dato una risposta non corretts',
					badInt : 'La risposta che hai dato non è un numero corretto',
					badSecurityNumber : 'Il tuo numero di sicurezza non è corretto',
					badUKVatAnswer : 'Numero UK VAT non corretto',
					badStrength : 'La password non è abbastanza sicura',
					badNumberOfSelectedOptionsStart : 'Devi scegliere almeno ',
					badNumberOfSelectedOptionsEnd : ' risposte',
					badAlphaNumeric : 'La risposta deve contenere solo caratteri alfanumerici ',
					badAlphaNumericExtra: ' e ',
					wrongFileSize : 'Il file che vuoi caricare è troppo grande',
					wrongFileType : 'Il file che vuoi caricare non è del tipo corretto',
					groupCheckedRangeStart : 'Scegli tra ',
					groupCheckedTooFewStart : 'Scegli almeno ',
					groupCheckedTooManyStart : 'Scegli un massimo di ',
					groupCheckedEnd : ' campo/i'
				};

				if( $('html').attr('lang')==='it-IT' ) {
					$.validate({
						language: ita,
						borderColorOnError : '',
						onSuccess : function(form) {
							var thankyouURL = $(form[0]).parents('.wpcf7').next().text();
							location.assign(thankyouURL);
						},
					});
				} else {
					$.validate({
						borderColorOnError : '',
						onSuccess : function(form) {
							var thankyouURL = $(form[0]).parents('.wpcf7').next().text();
							location.assign(thankyouURL);
						},
					});
				}

				// INIT AJAXFORM ON CF7 FORMS
				// --------------------------
				$('.wpcf7-form').ajaxForm();

			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
